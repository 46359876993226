/*
jQuery(document).ready(function() {
	console.log("NAVIGATORS MOUNT UP!");

	// Toggle Overlay
	/*
	$('#toggle').click(function() {
		$(this).toggleClass('active');
		$('#overlay').toggleClass('open');
		$('#logo').toggleClass('open');
		$('#info').toggleClass('open');
	});
});
*/

jQuery(window).scroll( function(){

	// Sticky Nav Style
	var headerHeight = jQuery('.header').outerHeight();
	var navHeight = jQuery('.navbar').outerHeight();
	var scrollPos = jQuery(window).scrollTop();
	var windowWidth = jQuery(window).width();
		
	if ( scrollPos >= 50 && windowWidth > 767 ) {
		jQuery('.navbar-brand').addClass('fixed');
		//console.log('STICK!');
	} else {
		jQuery('.navbar-brand').removeClass('fixed');
		//console.log('-UNSTICK!');
	}

});